@import '../index.css';

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: var(--fontcolor3);
    text-decoration: none;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--nav-size);
    background-color: var(--bgcolor3);
    padding: 0 1rem;
    border-bottom: var(--border);
    z-index: 1;
}

.navbar-nav {
    display: flex;
    justify-content: flex-end;
    max-width: 100%;
    height: 100%;
}

.nav-item {
    width: calc(var(--nav-size) * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.icon-button {
    --buton-size: calc(var(--nav-size) * 0.5);
    width: var(--buton-size);
    height: var(--buton-size);
    background-color: var(--bg-second);
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
}

.icon-button:hover{
    filter: brightness(1.2);
}

.icon-button svg {
    fill: var(--bgcolor3);
    width: 20px;
    height: 20px;
}

.logo-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .logo-container img {
    width: 40px;
    height: auto;
  }
  

  


