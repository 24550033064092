:root{
    font-family: 'dotgothic16';
    --primary-color: #2e2e2e;
    --secondary-color: #1b1b1b;
    --third-color: #080808;
    --fourth-color: #AB9CB8;
    --bg-first: #551a8b;
    --bg-second: #aa8cc5;
    --bg-third: #7547a2;
    --bg-orgn1: #e96c28;
    --bg-orgn2: #ea9828;
    --bg-orgn3: #fcba21;
    --fontcolor1: #0f0f0f;
    --fontcolor2: #2c3333;
    --fontcolor3: #f1eaff;
    --bgcolor1: #2ecc71;
    --bgcolor2: #fdfefe;
    --bgcolor3: #2e2e2e;
    --bgcolor4: #0766ad;
    --bgcolor5: #c5e898;
    --nav-size: 80px;
    --border: 1px solid #474a4d ;
    --border-radius: 8px;
    --speed: 500ms;
}

::-webkit-scrollbar {
    scroll-behavior: smooth;
    background-color: var(--bg-second);
}

::-webkit-scrollbar-thumb {
    background-color: var(--bg-first);
}
