@import '/src/index.css';

.wrapperbox {
    display: flex;
    height: 30vh;
    width: 65vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 10vh;
    background-color: var(--bgcolor3);
}

.loginbox {
    display: inline-block;
    width: 75%;
}

.wrapperheader {
    color: var(--bg-second);
}

.thisisemail {
    display: inline-block;
    width: 75%;
    background-color: var(--bg-primary);
    color: var(--bg-second);
}

.thisispassword {
    display: inline-block;
    width: 75%;
    background-color: var(--bg-primary);
    color: var(--bg-second);
}

.passforgot {
    color: var(--bg-second);
    text-decoration-line: underline;
}

.submitbutton {
    background-color: var(--orgn-first);
    color: var(--bg-second);
    width: 75%;
}




