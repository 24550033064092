@import '../index.css';

.catimage {
    width: 200px;
    height: 200px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: top;
    z-index: 1;
    opacity: 1;
}

.catimage:hover {
    background-color: aqua;
}