@import '../index.css';

.creatorwrapper {
    display: flex;
    height: 30vh;
    width: 65vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 10vh;
    background-color: var(--bgcolor3);
}

.creatorhead {
    color: var(--bg-second);

}

.creatoremail {
    display: inline-block;
    background-color: var(--bg-primary);
    width: 75%;
    color: var(--bg-second);
}

.creatorpassword {
    display: inline-block;
    background-color: var(--bg-primary);
    width: 75%;
    color: var(--bg-second);
}

.creatorbutton {
    background-color: var(--bgcolor3);
    color: var(--bg-second);
    width: 75%;   
}